import { Col, Divider, Modal, Row } from "antd";
import React from "react";

import { initialStateOfInquiry } from "../../pages/Inquiries";
import moment from "moment";

const ViewInquiry = ({ open, setState, state }) => {
  const handleCancel = () => {
    setState(initialStateOfInquiry);
  };

  return (
    <>
      <Modal
        title={`View Inquiry`}
        visible={open}
        onCancel={handleCancel}
        footer={null}
      >
        <div>
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <span style={{ fontSize: "16px", wordBreak: "break-word" }}>
                Name: {state?.data?.name}
              </span>
            </Col>
            <Col span={12}>
              <span style={{ fontSize: "16px", wordBreak: "break-word" }}>
                Created At:{" "}
                {state?.data?.createdAt
                  ? moment(state?.data?.createdAt).format("DD/MM/YYYY")
                  : "-"}
              </span>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <span style={{ fontSize: "16px", wordBreak: "break-word" }}>
                Email: {state?.data?.email}
              </span>
            </Col>
            <Col span={12}>
              <span style={{ fontSize: "16px", wordBreak: "break-word" }}>
                Phone: {state?.data?.phone}
              </span>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <span style={{ fontSize: "16px", wordBreak: "break-word" }}>
                Subject: {state?.data?.subject}
              </span>
            </Col>
            <Col span={12}>
              <span style={{ fontSize: "16px", wordBreak: "break-word" }}>
                Description: {state?.data?.description}
              </span>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default ViewInquiry;

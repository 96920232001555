import { Card, Tabs } from "antd";
import React, { useState } from "react";
import EditProfile from "./EditProfile";
import ChangePassword from "./ChangePassword";

const { TabPane } = Tabs;

const Profile = () => {
  const [currentTab, setCurrentTab] = useState("profile");

  const onChange = (key) => {
    setCurrentTab(key);
  };

  return (
    <>
      <h2>Profile</h2>
      <Tabs activeKey={currentTab} onChange={onChange} type="card">
        <TabPane tab="Edit Profile" key="profile">
          <Card>
            <EditProfile />
          </Card>
        </TabPane>
        <TabPane tab="Change Password" key="password">
          <Card>
            <ChangePassword />
          </Card>
        </TabPane>
      </Tabs>
    </>
  );
};

export default Profile;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Input, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import CustomPaymentPagination from "./CustomPaymentPagination";
import { paymentActions, selectPayment } from "../../redux/slices/paymentSlice";
import { helperMethods } from "../../utils/DebounceSearch/helperMethod";
import ChangeStatusModal from "./ChangeStatusModal";

export const initialStatusModal = {
  open: false,
  row: null,
};

const PaymentManagement = () => {
  const dispatch = useDispatch();

  const [payments, setPayments] = useState([]);
  const [statusModal, setStatusModal] = useState(initialStatusModal);

  const paymentData = useSelector(selectPayment);

  useEffect(() => {
    dispatch(paymentActions.transactionList({ limit: 10, page: 1 }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (paymentData) {
      setPayments(paymentData?.list);
    }
  }, [paymentData]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_, elm) => (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span style={{ textTransform: "capitalize" }}>
              {elm?.superUserId?.firstName || ""}{" "}
              {elm?.superUserId?.lastName || ""}
            </span>
            <span style={{ fontSize: "12px" }}>
              {elm?.isRenewal ? "(Renewal)" : ""}
            </span>
          </div>
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (_, elm) => <span>{elm?.superUserId?.email || ""}</span>,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      render: (_, elm) => <span>{elm?.superUserId?.mobile || ""}</span>,
    },
    {
      title: "Total License",
      dataIndex: "totalLicense",
      render: (_, elm) => (
        <span>{elm?.mainLicense + elm?.initialSubLicense || "-"}</span>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (_, elm) => {
        let finalAmount = "";

        if (elm?.amount) {
          finalAmount = `Rs ${elm?.amount}`;
        } else if (
          elm?.superUserId?.accountType === "demo" &&
          elm?.amount === 0
        ) {
          finalAmount = `0`;
        } else {
          finalAmount = "0";
        }

        return (
          <>
            <span>{finalAmount}</span>
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, elm) => renderStatus(elm?.status, elm),
    },
    {
      title: "Note",
      dataIndex: "note",
      render: (_, elm) => <span>{elm?.statusNote || "-"}</span>,
    },
    {
      title: "Coupon",
      dataIndex: "coupon",
      render: (_, elm) => (
        <span>
          {elm?.coupon?.amt ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span>
                  {Number(elm?.coupon?.amt).toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })}
                </span>
                <span style={{ fontSize: "12px" }}>({elm?.coupon?.name})</span>
              </div>
            </>
          ) : (
            "-"
          )}
        </span>
      ),
    },
  ];

  const renderStatus = (status, row) => {
    let statusColor = "";
    let statusBgColor = "";
    let statusText = "";

    switch (status) {
      case "pending":
        statusBgColor = "#ffdc73";
        statusColor = "#e07a04";
        statusText = "Pending";
        break;
      case "approved":
        statusBgColor = "#cff797";
        statusColor = "#65ab03";
        statusText = "Approved";
        break;
      case "rejected":
        statusBgColor = "#ffbbb5";
        statusColor = "#c41404";
        statusText = "Rejected";
        break;
      case "refund":
        statusBgColor = "#a7defc";
        statusColor = "#0489d1";
        statusText = "Refund";
        break;
      default:
        statusText = "-";
    }

    return (
      <span
        style={{
          textTransform: "capitalize",
          background: statusBgColor || "#c3c3c3",
          color: statusColor || "#000",
          fontWeight: 600,
          padding: "5px 10px",
          borderRadius: "5px",
          cursor: "pointer",
        }}
        onClick={() => handleChangeStatus(row)}
      >
        {statusText || "-"}
      </span>
    );
  };

  const handleChangeStatus = (row) => {
    setStatusModal((preState) => ({
      ...preState,
      open: !preState.open,
      row,
    }));
  };

  const searchInData = (e) => {
    const searchInput = e.target.value;

    dispatch(
      paymentActions.transactionSearch({
        searchText: searchInput,
      })
    );
  };

  // eslint-disable-next-line
  const debounceOnSearch = React.useCallback(
    helperMethods.debounce(searchInData, 400),
    []
  );

  return (
    <>
      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Input
            allowClear
            size="large"
            placeholder="Search..."
            prefix={<SearchOutlined />}
            onChange={(e) => {
              debounceOnSearch(e);
            }}
            onClear={(e) => debounceOnSearch()}
          />
        </div>
      </div>
      <div>
        <Card>
          <>
            <Table
              columns={columns}
              dataSource={payments}
              loading={false}
              rowKey="id"
              pagination={false}
              className="table-responsive"
            />
            <div style={{ marginTop: "20px" }}>
              <CustomPaymentPagination />
            </div>
          </>
        </Card>
      </div>

      <ChangeStatusModal
        open={statusModal.open}
        setOpen={setStatusModal}
        stateData={statusModal}
      />
    </>
  );
};

export default PaymentManagement;

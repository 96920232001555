import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { faqsActions } from "../../redux/slices/faqsSlice";
import { Card, Table, Button, Tooltip, message } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import moment from "moment";
import { deleteFaqsApi } from "../../redux/apis/dashboardApi";

const ListFaqs = ({ setState, state }) => {
  const columns = [
    {
      title: "Question",
      dataIndex: "title",
      render: (_, elm) => <span>{elm?.title || ""}</span>,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (_, elm) => (
        <span>
          {elm?.createdAt ? moment(elm?.createdAt).format("DD/MM/YYYY") : "-"}
        </span>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      align: "center",
      render: (_, elm) => (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            gap: 10,
          }}
        >
          <Tooltip title="View/Edit">
            <Button
              type="primary"
              size="small"
              onClick={() => handleEditFaqs(elm)}
            >
              <EditOutlined style={{ fontSize: "18px" }} />
            </Button>
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              danger
              onClick={() => {
                handleDelete(elm._id);
              }}
              size="middle"
            >
              <DeleteOutlined style={{ fontSize: "18px" }} />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  const dispatch = useDispatch();

  const faqsStates = useSelector((state) => state.faqs);

  useEffect(() => {
    dispatch(faqsActions.faqsList());
  }, [dispatch]);

  const handleDelete = async (id) => {
    try {
      let res = await deleteFaqsApi(id);
      if (res?.data?.message) {
        message.success(res?.data?.message);
        dispatch(faqsActions.faqsList());
      } else {
        message.error(res?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const handleEditFaqs = (row) => {
    setState((pre) => ({ ...pre, data: row, type: "edit", open: true }));
  };

  return (
    <>
      <div>
        <Card>
          <>
            <Table
              columns={columns}
              dataSource={faqsStates?.list || []}
              loading={faqsStates?.isLoading}
              rowKey="id"
              pagination={false}
              className="table-responsive"
            />
          </>
        </Card>
      </div>
    </>
  );
};

export default ListFaqs;

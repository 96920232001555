/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, userActions } from "../../redux/slices/userSlice";
import { Button, Card, Input, Table, Tooltip } from "antd";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";
import { PATH_DASHBOARD } from "../../routes/path";
import CustomPagination from "./Pagination";
import { helperMethods } from "../../utils/DebounceSearch/helperMethod";
import moment from "moment";

const UserList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = useSelector(selectUser);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    dispatch(userActions.userList({ limit: 10, page: 1 }));
  }, []);

  useEffect(() => {
    if (userData) {
      setUsers(userData?.list);
    }
  }, [userData]);

  // const handleDelete = async (id) => {
  //   try {
  //     const deleteRes = await deleteCustomerApi(id);
  //     if (deleteRes?.data?.success) {
  //       message.success(deleteRes?.data?.message);
  //       dispatch(customerActions.customerList({ limit: 10, page: 1 }));
  //     } else {
  //       message.error(deleteRes?.data?.message);
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //     message.error(error?.response.data?.message);
  //   }
  // };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_, elm) => (
        <span>
          {elm?.firstName || ""} {elm?.lastName || ""}
        </span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (_, elm) => <span>{elm?.email || ""}</span>,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      render: (_, elm) => <span>{elm?.mobile || ""}</span>,
    },
    {
      title: "Account Type",
      dataIndex: "accountType",
      render: (_, elm) => (
        <>
          {elm?.role?.roleName === "superUser" ? (
            <span style={{ textTransform: "capitalize" }}>
              {elm?.accountType || ""}
            </span>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (_, elm) => (
        <span style={{ textTransform: "capitalize" }}>
          {elm?.role?.roleName === "superUser"
            ? "Super Admin"
            : elm?.role?.roleName === "userAdmin"
            ? "Admin"
            : elm?.role?.roleName === "subUser"
            ? "User"
            : ""}
        </span>
      ),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      render: (_, elm) => (
        <span>
          {elm?.createdBy?.firstName || ""} {elm?.createdBy?.lastName || ""}
        </span>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (_, elm) => (
        <span>
          {elm?.createdAt ? moment(elm?.createdAt).format("DD/MM/YYYY") : "-"}
        </span>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      align: "center",
      render: (_, elm) => (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            gap: 10,
          }}
        >
          <Tooltip title="View/Edit">
            <Button
              type="primary"
              size="small"
              onClick={() => navigate(PATH_DASHBOARD.editUser(elm._id))}
            >
              <EditOutlined style={{ fontSize: "18px" }} />
            </Button>
          </Tooltip>
          {/* <Tooltip title="Delete">
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                handleDelete(elm._id);
              }}
              size="middle"
            />
          </Tooltip> */}
        </div>
      ),
    },
  ];

  const searchInData = (e) => {
    const searchInput = e.target.value;

    dispatch(
      userActions.userSearch({
        searchText: searchInput,
      })
    );
  };

  const debounceOnSearch = React.useCallback(
    helperMethods.debounce(searchInData, 400),
    []
  );

  return (
    <>
      <h2>User List</h2>
      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Input
            size="large"
            placeholder="Search..."
            prefix={<SearchOutlined />}
            onChange={(e) => {
              debounceOnSearch(e);
            }}
            allowClear
            onClear={(e) => debounceOnSearch()}
          />
        </div>
        {/* <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          size="large"
          onClick={() => navigate(PATH_DASHBOARD.userList)}
        >
          New Customer
        </Button> */}
      </div>
      <div>
        <Card>
          <>
            <Table
              columns={columns}
              dataSource={users}
              loading={userData?.isLoading}
              rowKey="id"
              pagination={false}
              className="table-responsive"
            />
            <div style={{ marginTop: "20px" }}>
              <CustomPagination />
            </div>
          </>
        </Card>
      </div>
    </>
  );
};

export default UserList;

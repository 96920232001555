import React from "react";
import PaymentManagement from "./PaymentManagement";

const PaymentManagementIndex = () => {
  return (
    <>
      <h2>Price Management</h2>

      <PaymentManagement />
    </>
  );
};

export default PaymentManagementIndex;

import React, { useState } from "react";
import { Tabs } from "antd";
import TermsAndCondition from "./TermsAndCondition";
import PrivacyPolicy from "./PrivacyPolicy";
import AboutUs from "./AboutUs";

const { TabPane } = Tabs;

const CMSPages = () => {
  const [currentTab, setCurrentTab] = useState("terms");

  const onChange = (key) => {
    setCurrentTab(key);
  };

  return (
    <>
      <h2>Content Management System</h2>
      <Tabs activeKey={currentTab} onChange={onChange} type="card">
        <TabPane tab="Terms & Conditions" key="terms">
          <TermsAndCondition />
        </TabPane>
        <TabPane tab="Privacy Policy" key="privacy">
          <PrivacyPolicy />
        </TabPane>
        <TabPane tab="About Us" key="about">
          <AboutUs />
        </TabPane>
      </Tabs>
    </>
  );
};

export default CMSPages;

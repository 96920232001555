import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { Card, Table, Button, Tooltip, Input } from "antd";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";

import { inquiryActions } from "../../redux/slices/inquirySlice";
import { helperMethods } from "../../utils/DebounceSearch/helperMethod";

const ListInquiries = ({ setState, state }) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_, elm) => <span>{elm?.name || ""}</span>,
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      render: (_, elm) => <span>{elm?.phone || ""}</span>,
    },
    {
      title: "Subject",
      dataIndex: "subject",
      render: (_, elm) => <span>{elm?.subject || ""}</span>,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (_, elm) => (
        <span>
          {elm?.createdAt ? moment(elm?.createdAt).format("DD/MM/YYYY") : "-"}
        </span>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      align: "center",
      render: (_, elm) => (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            gap: 10,
          }}
        >
          <Tooltip title="View">
            <Button
              type="primary"
              size="small"
              onClick={() => handleViewInquiry(elm)}
            >
              <EyeOutlined style={{ fontSize: "18px" }} />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  const dispatch = useDispatch();

  const inquiryStates = useSelector((state) => state.inquiry);

  useEffect(() => {
    dispatch(inquiryActions.inquiryList());
  }, [dispatch]);

  const handleViewInquiry = (row) => {
    setState((pre) => ({ ...pre, data: row, open: true }));
  };

  const searchInData = (e) => {
    const searchInput = e.target.value;

    dispatch(
      inquiryActions.inquirySearch({
        searchText: searchInput,
      })
    );
  };

  // eslint-disable-next-line
  const debounceOnSearch = React.useCallback(
    helperMethods.debounce(searchInData, 400),
    []
  );

  return (
    <>
      <div>
        <div
          style={{
            marginBottom: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Input
              allowClear
              size="large"
              placeholder="Search..."
              prefix={<SearchOutlined />}
              onChange={(e) => {
                debounceOnSearch(e);
              }}
              onClear={(e) => debounceOnSearch()}
            />
          </div>
        </div>

        <Card>
          <>
            <Table
              columns={columns}
              dataSource={inquiryStates?.list || []}
              loading={inquiryStates?.isLoading}
              rowKey="id"
              pagination={false}
              className="table-responsive"
            />
          </>
        </Card>
      </div>
    </>
  );
};

export default ListInquiries;

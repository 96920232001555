import saga from "redux-saga";
// import { all, fork } from 'redux-saga/effects'
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import { rootPersistConfig, rootReducer } from "./rootReducer";
import { authSaga } from "./slices/authSlice";
import { all, fork } from "redux-saga/effects";
import { userSaga } from "./slices/userSlice";
import { subcriptionSaga } from "./slices/subcriptionSlice";
import { payemntSaga } from "./slices/paymentSlice";
import { faqsSaga } from "./slices/faqsSlice";
import { inquirySaga } from "./slices/inquirySlice";

function* RootSaga() {
  yield all([fork(authSaga)]);
  yield all([fork(userSaga)]);
  yield all([fork(subcriptionSaga)]);
  yield all([fork(payemntSaga)]);
  yield all([fork(faqsSaga)]);
  yield all([fork(inquirySaga)]);
}

const sagaMiddlerware = saga();

const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
    sagaMiddlerware,
  ],
  devTools: process.env.NODE_ENV !== "production",
});

sagaMiddlerware.run(RootSaga);

const persistor = persistStore(store);

export { store, persistor };

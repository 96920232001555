/* eslint-disable */ 
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { Card, Col, Form, Row, Spin, message } from "antd";
import { PATH_DASHBOARD } from "../../routes/path";
import { editUserApi, getUserApi } from "../../redux/apis/dashboardApi";
import AddEditUser from "../../sections/users/AddEditUser";

const UserEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const params = useParams();
  const [viewData, setViewData] = useState(null);
  const [isViewData, setIsViewData] = useState(null);

  const [addEditUserForm] = Form.useForm();

  useEffect(() => {
    if (location) {
      if (location.pathname.includes("edit")) {
        setIsEdit(true);
      } else {
        setIsEdit(false);
      }
    }
  }, [location]);

  const handleCancle = () => {
    navigate(PATH_DASHBOARD.userList);
  };

  useEffect(() => {
    if (isEdit && params) {
      (async () => {
        try {
          setIsViewData(true);
          const viewRes = await getUserApi(params?.id);
          if (viewRes?.data?.success) {
            setIsViewData(false);
            setViewData(viewRes?.data?.data);
          } else {
            setIsViewData(false);
            setViewData(null);
          }
        } catch (error) {
          console.log("error ---->", error);
          setIsViewData(false);
          setViewData(null);
        }
      })();
    }
  }, [params, isEdit]);

  useEffect(() => {
    if (viewData) {
      addEditUserForm.setFieldsValue({
        firstName: viewData?.firstName,
        lastName: viewData?.lastName,
        companyName: viewData?.companyName,
        industry: viewData?.industry,
        designation: viewData?.designation,
        email: viewData?.email,
        mobile: viewData?.mobile,
        department: viewData?.department,
      });
    }
  }, [viewData, isEdit]);

  const onFinish = async (values) => {
    try {
      setIsSubmit(true);
      const data = {
        ...values,
      };

      const editRes = await editUserApi(params?.id, data);

      if (editRes?.data?.success) {
        message.success(editRes.data.message);
        setIsSubmit(false);
        setTimeout(() => {
          navigate(PATH_DASHBOARD.userList);
        }, 1000);
      } else {
        setIsSubmit(false);
        message.error(editRes.data.message);
      }
    } catch (error) {
      setIsSubmit(false);
      message.error(error?.response.data?.message);
    }
  };

  if (isViewData && isEdit) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <Spin />
        </div>
      </>
    );
  }

  return (
    <>
      <Card title="Edit User">
            <div className="">
              <Form
                name="addEditUserForm"
                layout="vertical"
                form={addEditUserForm}
                onFinish={onFinish}
              >
                <AddEditUser
                  isEdit={isEdit}
                  isSubmit={isSubmit}
                  handleCancle={handleCancle}
                />
              </Form>
            </div>
      </Card>
    </>
  );
};

export default UserEdit;

import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import PageNotFound from "../pages/PageNotFound";
import GuestGuard from "../auth/GuestGuard";
import AuthGuard from "../auth/AuthGuard";
import { useDispatch } from "react-redux";
import { authActions } from "../redux/slices/authSlice";
import { useEffect } from "react";
import { PATH_AUTH, PATH_DASHBOARD } from "./path";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import UserList from "../pages/Users/UserList";
import Main from "../components/layout/Main";
import UserEdit from "../pages/Users/UserEdit";
import CMSPages from "../pages/cms";
import Presets from "../pages/Presets/Presets";
import Profile from "../pages/Profile";
import PricingManagement from "../pages/PricingManagement";
import PaymentManagementIndex from "../pages/paymentManagement";
import Faqs from "../pages/Faqs";
import InquiriesPage from "../pages/Inquiries";

export default function Router() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isAuth = pathname.includes("/app");
  const homeUrl = pathname.includes("/");

  useEffect(() => {
    if (isAuth || homeUrl) {
      dispatch(authActions.isLogin());
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Routes>
        <Route>
          <Route
            path={PATH_AUTH.login}
            element={
              <GuestGuard>
                <SignIn />
              </GuestGuard>
            }
          />
          <Route
            path={PATH_AUTH.register}
            element={
              <GuestGuard>
                <SignUp />
              </GuestGuard>
            }
          />
        </Route>
        <Route element={<Main />}>
          <Route
            path={PATH_DASHBOARD.root}
            element={
              <AuthGuard>
                <UserList />
              </AuthGuard>
            }
          />

          <Route
            path="/app/user/edit/:id"
            element={
              <AuthGuard>
                <UserEdit />
              </AuthGuard>
            }
          />

          <Route
            path="/app/cms"
            element={
              <AuthGuard>
                <CMSPages />
              </AuthGuard>
            }
          />

          <Route
            path="/app/preset"
            element={
              <AuthGuard>
                <Presets />
              </AuthGuard>
            }
          />

          <Route
            path="/app/profile"
            element={
              <AuthGuard>
                <Profile />
              </AuthGuard>
            }
          />

          <Route
            path="/app/price-management"
            element={
              <AuthGuard>
                <PricingManagement />
              </AuthGuard>
            }
          />

          <Route
            path="/app/payment-management"
            element={
              <AuthGuard>
                <PaymentManagementIndex />
              </AuthGuard>
            }
          />

          <Route
            path="/app/faqs"
            element={
              <AuthGuard>
                <Faqs />
              </AuthGuard>
            }
          />

          <Route
            path="/app/inquiries"
            element={
              <AuthGuard>
                <InquiriesPage />
              </AuthGuard>
            }
          />
        </Route>
        <Route
          path="/"
          element={<Navigate to={PATH_AUTH.root} replace />}
          index={true}
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

import { Button, Col, Form, Input, Row } from "antd";
import React from "react";

const rules = {
  firstName: [{ required: true, message: "Please Enter First Name" }],
  lastName: [{ required: true, message: "Please Enter Last Name" }],
  companyName: [{ required: true, message: "Please Enter Company Name" }],
  industry: [{ required: true, message: "Please Enter Industry" }],
  designation: [{ required: true, message: "Please Enter Designation" }],
  email: [
    { required: true, message: "Please Enter Email" },
    {
      type: "email",
      message: "Please Enter A Valid Email",
    },
  ],
  mobile: [
    {
      required: true,
      message: "Please Enter Mobile Number",
    },
    {
      max: 10,
      message: "Please Enter Valid Phone Number",
    },
  ],
};

const AddEditUser = ({ isEdit, isSubmit, handleCancle }) => {
  return (
    <>
      <Row gutter={12}>
        <Col xs={24} sm={24} md={20} lg={12} xl={12}>
          <Form.Item
            label="First Name"
            name="firstName"
            rules={rules.firstName}
          >
            <Input placeholder="First Name" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={20} lg={12} xl={12}>
          <Form.Item label="Last Name" name="lastName" rules={rules.lastName}>
            <Input placeholder="Last Name" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col xs={24} sm={24} md={20} lg={12} xl={12}>
          <Form.Item
            label="Company Name"
            name="companyName"
            rules={rules.companyName}
          >
            <Input placeholder="Company Name" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={20} lg={12} xl={12}>
          <Form.Item label="Industry" name="industry" rules={rules.industry}>
            <Input placeholder="Industry" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col xs={24} sm={24} md={20} lg={12} xl={12}>
          <Form.Item
            label="Department/Division"
            name="department"
            rules={rules.department}
          >
            <Input placeholder="Department/Division" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={20} lg={12} xl={12}>
          <Form.Item
            label="Designation"
            name="designation"
            rules={rules.designation}
          >
            <Input placeholder="Designation" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col xs={24} sm={24} md={20} lg={12} xl={12}>
          <Form.Item label="Email Id" name="email" rules={rules.email}>
            <Input placeholder="Email Id" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={20} lg={12} xl={12}>
          <Form.Item label="Mobile Number" rules={rules.mobile} name="mobile">
            <Input
              placeholder="Mobile Number"
              className="hide-arrows"
              style={{ width: "100%" }}
              stringMode={true}
              type="number"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12} style={{ display: "flex", justifyContent: "flex-end" }}>
        <Col span={6}>
          <Button
            onClick={handleCancle}
            style={{ width: "100%" }}
            type="default"
          >
            Cancel
          </Button>
        </Col>
        <Col span={6}>
          <Button
            style={{ width: "100%" }}
            type="primary"
            htmlType="submit"
            loading={isSubmit}
          >
            Submit
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default AddEditUser;

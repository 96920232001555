import { createSlice, createSelector } from "@reduxjs/toolkit";
import { put, select, takeLatest } from "redux-saga/effects";
import { listInquiriesApi } from "../apis/dashboardApi";

const initialState = {
  isLoading: true,
  error: null,
  success: false,
  list: [],
  viewData: null,
  sort: "createdAt",
  sortType: "desc",
  searchText: "",
  isNext: false,
};

const inquirySlice = createSlice({
  name: "inquiry",
  initialState,
  reducers: {
    inquiryList(state) {
      state.isLoading = true;
      state.searchText = "";
      state.isNext = false;
    },
    inquiryListSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.list = action.payload.data;
    },
    inquiryListFailed(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
      state.isNext = false;
      state.list = [];
    },
    inquirySearch(state, action) {
      state.searchText = action.payload.searchText;
    },
  },
});

export const {
  actions: inquiryActions,
  reducer: inquiryReducer,
  name: inquiryKey,
} = inquirySlice;

const selectDomain = (state) => state.inquiry || initialState;

export const selectInquiry = createSelector(
  [selectDomain],
  (inquiryState) => inquiryState
);

export const getState = (state) => state.inquiry;

export function* inquiryListReq() {
  try {
    const states = yield select(getState);
    const res = yield listInquiriesApi(states);

    if (res.data.success) {
      yield put(inquiryActions.inquiryListSuccess(res.data));
    } else {
      yield put(inquiryActions.inquiryListFailed(res.data));
    }
  } catch (error) {
    yield put(inquiryActions.inquiryListFailed(error));
  }
}

export function* inquirySaga() {
  yield takeLatest(inquiryActions.inquiryList.type, inquiryListReq);
  yield takeLatest(inquiryActions.inquirySearch.type, inquiryListReq);
}

import { useState, useEffect } from "react";
import {
  Row,
  Col,
  message,
  Divider,
  Space,
  Avatar,
  Typography,
  Popover,
} from "antd";
import userLogo from "../../assets/images/user.png";
import { authActions, selectLoggedUser } from "../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { LogoutOutlined } from "@ant-design/icons";

function Header() {
  const { Text } = Typography;

  useEffect(() => window.scrollTo(0, 0));

  const loggedUser = useSelector(selectLoggedUser);

  const [openProfile, setOpenProfile] = useState(false);

  const dispatch = useDispatch();

  const handleOpenProfile = (newOpen) => {
    setOpenProfile(newOpen);
  };

  const handleLogout = async () => {
    try {
      dispatch(authActions.logout());

      setTimeout(() => {
        window.location.reload();
      }, 800);
    } catch (error) {
      message.error(error);
    }
  };

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24} md={6}></Col>
        <Col span={24} md={18} className="header-control">
          <Popover
            open={openProfile}
            onOpenChange={handleOpenProfile}
            content={
              <>
                <Row
                  style={{
                    padding: 12,
                    cursor: "auto",
                  }}
                >
                  <Space size={"middle"}>
                    <Col>
                      <Avatar
                        size={"large"}
                        style={{
                          cursor: "pointer",
                          marginTop: "10px",
                          border: "1px solid #d3d3d3",
                        }}
                        src={loggedUser?.image ?? userLogo}
                      />
                    </Col>
                    <Col>
                      <Row>
                        <Text>
                          {loggedUser?.firstName} {loggedUser?.lastName}
                        </Text>
                      </Row>
                      <Text
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        {loggedUser?.email}
                      </Text>
                    </Col>
                  </Space>
                </Row>
                <Divider
                  style={{
                    margin: 0,
                  }}
                />
                <div
                  style={{
                    padding: "5px",
                  }}
                  onClick={() => {
                    setOpenProfile(false);
                    handleLogout();
                  }}
                >
                  <div style={{ cursor: "pointer", padding: "12px" }}>
                    <Row
                      align={"middle"}
                      style={{
                        gap: 10,
                      }}
                    >
                      <LogoutOutlined
                        style={{
                          fontSize: "19px",
                        }}
                      />
                      <Text
                        style={{
                          fontSize: "15px",
                        }}
                      >
                        Log out
                      </Text>
                    </Row>
                  </div>
                </div>
              </>
            }
            trigger="click"
            arrow={false}
            placement="bottomLeft"
          >
            <Avatar
              size={"large"}
              style={{
                cursor: "pointer",
                marginTop: "10px",
                border: "1px solid #d3d3d3",
              }}
              src={loggedUser?.image ?? userLogo}
            />
          </Popover>
        </Col>
      </Row>
    </>
  );
}

export default Header;

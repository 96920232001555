/* eslint-disable */
import { Button, Card, Col, Input, Modal, Row, Table, message } from "antd";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { selectLoggedUser } from "../../redux/slices/authSlice";
import {
  apiDeletePresetInList,
  apiGetPresetList,
  apiPutPresetList,
} from "../../redux/apis/dashboardApi";

const initialState = {
  potentialList: [],
  consumptionList: [],
  unitList: [],
  addPotential: null,
  addConsumption: null,
  addUnit: null,
  type: null,
  model: false,
};

const Presets = () => {
  const loggedUser = useSelector(selectLoggedUser);

  const [state, setState] = useState(initialState);

  function compareSorting(list) {
    return list.slice().sort((a, b) => {
      const numA = parseInt(a);
      const numB = parseInt(b);

      if (!isNaN(numA) && !isNaN(numB)) {
        return numA - numB;
      } else {
        return a.localeCompare(b);
      }
    });
  }

  const presetListFunc = async () => {
    try {
      const res = await apiGetPresetList();

      if (res?.data?.success) {
        let potentialList =
          res.data?.data?.potentialValue?.length > 0
            ? compareSorting(res?.data?.data?.potentialValue)
            : [];

        let consumptionList =
          res.data?.data?.consumptionValue?.length > 0
            ? compareSorting(res?.data?.data?.consumptionValue)
            : [];

        let unitList =
          res.data?.data?.unitValue?.length > 0
            ? compareSorting(res?.data?.data?.unitValue)
            : [];

        setState({
          potentialList,
          consumptionList,
          unitList,
          model: false,
          addConsumption: null,
          addPotential: null,
          addUnit: null,
          type: null,
        });
      } else {
        message.error(res?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    if (loggedUser) {
      presetListFunc();
    }
  }, []);

  const handleAddPresetValues = async (type) => {
    try {
      let palyoad = {};
      palyoad.type = type;

      if (type === "potential") {
        if (state.addPotential) {
          palyoad.potentialValue = state.addPotential;
        } else {
          message.error("Please enter potential value");
        }
      }

      if (type === "consumption") {
        if (state.addConsumption) {
          palyoad.consumptionValue = state.addConsumption;
        } else {
          message.error("Please enter consumption value");
        }
      }

      if (type === "unit") {
        if (state.addUnit) {
          palyoad.unitValue = state.addUnit;
        } else {
          message.error("Please enter unit value");
        }
      }

      if (
        palyoad.potentialValue ||
        palyoad.consumptionValue ||
        palyoad.unitValue
      ) {
        const res = await apiPutPresetList(palyoad);

        if (res?.data?.success) {
          message.success(res?.data?.message);

          setState({
            ...state,
            addConsumption: null,
            addPotential: null,
            addUnit: null,
          });

          setTimeout(() => {
            presetListFunc();
          }, 700);
        } else {
          message.error(res?.data?.message);
        }
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const handleOpenListModel = (type) => {
    if (type) {
      setState({
        ...state,
        type,
        model: true,
      });
    }
  };

  const handleCloseModel = () => {
    setState({
      ...state,
      type: null,
      model: false,
    });

    setTimeout(() => {
      presetListFunc();
    }, 700);
  };

  const handleDelete = async (row) => {
    try {
      let palyoad = {};
      palyoad.type = state.type;

      if (state.type === "potential") {
        if (row) {
          palyoad.potentialValue = row;
        } else {
          message.error("Please enter potential value");
        }
      }

      if (state.type === "consumption") {
        if (row) {
          palyoad.consumptionValue = row;
        } else {
          message.error("Please enter consumption value");
        }
      }

      if (state.type === "unit") {
        if (row) {
          palyoad.unitValue = row;
        } else {
          message.error("Please enter unit value");
        }
      }

      if (
        palyoad.potentialValue ||
        palyoad.consumptionValue ||
        palyoad.unitValue
      ) {
        const res = await apiDeletePresetInList(palyoad);

        if (res?.data?.success) {
          message.success(res?.data?.message);

          handleCloseModel();
        } else {
          message.error(res?.data?.message);
        }
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  return (
    <>
      <h2>Presets</h2>
      <Card>
        <div style={{ marginTop: "20px" }}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24}>
              <span>Potential Value</span>
              <div style={{ display: "flex", gap: "20px", marginTop: "5px" }}>
                <Input
                  style={{
                    width: "100%",
                  }}
                  placeholder="Potential Value"
                  value={state.addPotential}
                  onChange={(event) =>
                    setState({ ...state, addPotential: event?.target?.value })
                  }
                />
                <Button
                  onClick={() => handleAddPresetValues("potential")}
                  type="primary"
                >
                  Add
                </Button>

                <Button danger onClick={() => handleOpenListModel("potential")}>
                  List
                </Button>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: "20px" }} gutter={16}>
            <Col xs={24} sm={24} md={24}>
              <span>Consumption Volume in Potential Mapping</span>
              <div style={{ display: "flex", gap: "20px", marginTop: "5px" }}>
                <Input
                  style={{
                    width: "100%",
                  }}
                  placeholder="Consumption Volume in Potential Mapping"
                  value={state.addConsumption}
                  onChange={(event) =>
                    setState({ ...state, addConsumption: event?.target?.value })
                  }
                />
                <Button
                  type="primary"
                  onClick={() => handleAddPresetValues("consumption")}
                >
                  Add
                </Button>

                <Button
                  danger
                  onClick={() => handleOpenListModel("consumption")}
                >
                  List
                </Button>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: "20px" }} gutter={16}>
            <Col xs={16} sm={16} md={24}>
              <span>Unit For QTY/Volume</span>
              <div style={{ display: "flex", gap: "20px", marginTop: "5px" }}>
                <Input
                  style={{
                    width: "100%",
                  }}
                  placeholder="Unit For QTY/Volume"
                  value={state.addUnit}
                  onChange={(event) =>
                    setState({ ...state, addUnit: event?.target?.value })
                  }
                />
                <Button
                  type="primary"
                  onClick={() => handleAddPresetValues("unit")}
                >
                  Add
                </Button>

                <Button danger onClick={() => handleOpenListModel("unit")}>
                  List
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Card>

      <Modal
        visible={state.model}
        onCancel={() => handleCloseModel()}
        title={
          <span style={{ textTransform: "capitalize" }}>{state.type} List</span>
        }
        footer={null}
      >
        <div>
          {state.type === "potential" && (
            <div>
              {state?.potentialList?.length > 0 && (
                <Table
                  bordered
                  pagination={{
                    pageSize: 5,
                  }}
                  dataSource={state.potentialList}
                  columns={[
                    {
                      title: "Name",
                      dataIndex: "name",
                      render: (val, elm) => {
                        return <span>{elm}</span>;
                      },
                    },
                    {
                      title: "Action",
                      width: "20%",
                      render: (val, elm) => {
                        return (
                          <Button
                            size="small"
                            danger
                            onClick={() => handleDelete(elm)}
                          >
                            Delete
                          </Button>
                        );
                      },
                    },
                  ]}
                />
              )}
            </div>
          )}

          {state.type === "consumption" && (
            <div>
              {state?.consumptionList?.length > 0 && (
                <Table
                  bordered
                  pagination={{
                    pageSize: 5,
                  }}
                  dataSource={state.consumptionList}
                  columns={[
                    {
                      title: "Name",
                      dataIndex: "name",
                      render: (val, elm) => {
                        return <span>{elm}</span>;
                      },
                    },
                    {
                      title: "Action",
                      width: "20%",
                      render: (val, elm) => {
                        return (
                          <Button
                            size="small"
                            danger
                            onClick={() => handleDelete(elm)}
                          >
                            Delete
                          </Button>
                        );
                      },
                    },
                  ]}
                />
              )}
            </div>
          )}

          {state.type === "unit" && (
            <div>
              {state?.unitList?.length > 0 && (
                <Table
                  bordered
                  pagination={{
                    pageSize: 5,
                  }}
                  dataSource={state.unitList}
                  columns={[
                    {
                      title: "Name",
                      dataIndex: "name",
                      render: (val, elm) => {
                        return <span>{elm}</span>;
                      },
                    },
                    {
                      title: "Action",
                      width: "20%",
                      render: (val, elm) => {
                        return (
                          <Button
                            size="small"
                            danger
                            onClick={() => handleDelete(elm)}
                          >
                            Delete
                          </Button>
                        );
                      },
                    },
                  ]}
                />
              )}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default Presets;

export const helperMethods = {
  debounce(func, wait, eventId) {
    let timeout;
    return function (...args) {
      // console.log("args -----", args)
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  },
};

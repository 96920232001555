import React, { useState } from "react";

import ViewInquiry from "../../sections/inquiry/ViewInquiry";
import ListInquiries from "./ListInquiries";

export const initialStateOfInquiry = {
  open: false,
  data: null,
  isLoading: false,
};

const InquiriesPage = () => {
  const [state, setState] = useState(initialStateOfInquiry);

  return (
    <>
      <h2>Inquiries Manage</h2>

      <div>
        <ListInquiries setState={setState} state={state} />
      </div>

      <ViewInquiry open={state.open} setState={setState} state={state} />
    </>
  );
};

export default InquiriesPage;

import { Divider, Menu } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import { PATH_DASHBOARD } from "../../routes/path";
import {
  ApartmentOutlined,
  DollarOutlined,
  NotificationOutlined,
  SettingOutlined,
  TeamOutlined,
  WalletOutlined,
  QuestionCircleOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import logo from "../../assets/images/logo.jpg";

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");

  return (
    <>
      <div>
        <div className="brand">
          <img src={logo} alt="logo" />
        </div>
        <Divider />
        <Menu theme="light" mode="inline">
          <Menu.Item style={{ marginBottom: "10px" }} key="1">
            <NavLink to={PATH_DASHBOARD.root}>
              <span
                className="icon"
                style={{
                  background: page.includes("user") ? color : "",
                }}
              >
                <TeamOutlined style={{ fontSize: "18px" }} />
              </span>
              <span className="label">Users</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item style={{ marginBottom: "10px" }} key="2">
            <NavLink to={PATH_DASHBOARD.inquiries}>
              <span
                className="icon"
                style={{
                  background: page.includes("inquries") ? color : "",
                }}
              >
                <FileSearchOutlined style={{ fontSize: "18px" }} />
              </span>
              <span className="label">Inquiries</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item style={{ marginBottom: "10px" }} key="2">
            <NavLink to={PATH_DASHBOARD.cms}>
              <span
                className="icon"
                style={{
                  background: page.includes("cms") ? color : "",
                }}
              >
                <NotificationOutlined style={{ fontSize: "18px" }} />
              </span>
              <span className="label">CMS</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item style={{ marginBottom: "10px" }} key="2">
            <NavLink to={PATH_DASHBOARD.faqs}>
              <span
                className="icon"
                style={{
                  background: page.includes("faqs") ? color : "",
                }}
              >
                <QuestionCircleOutlined style={{ fontSize: "18px" }} />
              </span>
              <span className="label">FAQs Manage</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item style={{ marginBottom: "10px" }} key="3">
            <NavLink to={PATH_DASHBOARD.preset}>
              <span
                className="icon"
                style={{
                  background: page.includes("preset") ? color : "",
                }}
              >
                <ApartmentOutlined style={{ fontSize: "18px" }} />
              </span>
              <span className="label">Presets</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item style={{ marginBottom: "10px" }} key="4">
            <NavLink to={PATH_DASHBOARD.pricingManagement}>
              <span
                className="icon"
                style={{
                  background: page.includes("pricingManagement") ? color : "",
                }}
              >
                <WalletOutlined style={{ fontSize: "18px" }} />
              </span>
              <span className="label">Price Manage</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item style={{ marginBottom: "10px" }} key="4">
            <NavLink to={PATH_DASHBOARD.paymentManagement}>
              <span
                className="icon"
                style={{
                  background: page.includes("paymentManagement") ? color : "",
                }}
              >
                <DollarOutlined style={{ fontSize: "18px" }} />
              </span>
              <span className="label">Payment Manage</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item style={{ marginBottom: "10px" }} key="4">
            <NavLink to={PATH_DASHBOARD.profile}>
              <span
                className="icon"
                style={{
                  background: page.includes("profile") ? color : "",
                }}
              >
                <SettingOutlined style={{ fontSize: "18px" }} />
              </span>
              <span className="label">Profile</span>
            </NavLink>
          </Menu.Item>
        </Menu>
      </div>
    </>
  );
}

export default Sidenav;

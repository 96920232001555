import { Input, Modal, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { initialStatusModal } from "./PaymentManagement";
import { editTransactionApi } from "../../redux/apis/dashboardApi";
import { paymentActions } from "../../redux/slices/paymentSlice";

const statusOptions = [
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Approved",
    value: "approved",
  },
  {
    label: "Rejected",
    value: "rejected",
  },
  {
    label: "Refund",
    value: "refund",
  },
];

const initialStatus = {
  status: null,
  note: "",
  isLoading: false,
};

const ChangeStatusModal = ({ open, setOpen, stateData }) => {
  const dispatch = useDispatch();

  const [statusValue, setStatusValue] = useState(initialStatus);

  useEffect(() => {
    if (open) {
      setStatusValue((preState) => ({
        ...preState,
        status: stateData?.row?.status,
      }));
    }
    // eslint-disable-next-line
  }, [open]);

  const handleChangeStatus = (status) => {
    setStatusValue((preState) => ({
      ...preState,
      status,
    }));
  };

  const handleChangeNote = (event) => {
    setStatusValue((preState) => ({
      ...preState,
      note: event.target.value,
    }));
  };

  const handleCancel = () => {
    setStatusValue(initialStatus);
    setOpen(initialStatusModal);
  };

  const handleSubmit = async () => {
    try {
      if (statusValue.status && statusValue.note) {
        const payload = {
          status: statusValue?.status,
          statusNote: statusValue?.note,
        };

        setStatusValue((preState) => ({
          ...preState,
          isLoading: true,
        }));

        const res = await editTransactionApi(stateData?.row?._id, payload);

        if (res?.data?.success) {
          message.success("Status Updated Successfully");
          setStatusValue(initialStatus);
          setOpen(initialStatusModal);
          dispatch(paymentActions.transactionList({ limit: 10, page: 1 }));
        } else {
          message.error(res?.data?.message);
        }
      } else {
        message.error("Please Enter Status And Note");
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  return (
    <>
      <Modal
        title="Change Status"
        visible={open}
        okText="Submit"
        onCancel={handleCancel}
        onOk={handleSubmit}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <div>
            <span>Status</span>
            <Select
              placeholder="Select Status"
              value={statusValue.status}
              options={statusOptions}
              onChange={handleChangeStatus}
              style={{ width: "100%" }}
            />
          </div>
          <div>
            <span>Note</span>
            <Input.TextArea
              rows={2}
              placeholder="Add Note"
              value={statusValue.note}
              onChange={handleChangeNote}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ChangeStatusModal;

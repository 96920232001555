import React from "react";
import { Button, Row, Col, Dropdown, Menu } from "antd";
import { LeftOutlined, RightOutlined, DownOutlined } from "@ant-design/icons";
import { userActions, selectUser } from "../../redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";

export function CustomPagination() {
  const pageSizeOptions = ["10", "20", "50"];
  const usersData = useSelector(selectUser);
  const dispatch = useDispatch();

  const Mymenu = (
    <Menu>
      {pageSizeOptions.map((e, i) => (
        <Menu.Item
          onClick={() => {
            dispatch(userActions.userListLimit({ limit: Number(e) }));
          }}
          key={i}
        >
          {e}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Row gutter={10}>
          <Col>
            <Button
              size="middle"
              disabled={usersData.page <= 1}
              onClick={() => {
                dispatch(
                  userActions.userListPageLimit({
                    page: usersData.page - 1,
                  })
                );
              }}
            >
              <LeftOutlined />
            </Button>
          </Col>
          <Col>
            <Button size="middle">{usersData.page}</Button>
          </Col>
          <Col>
            <Button
              size="middle"
              disabled={!usersData.isNext}
              onClick={() => {
                dispatch(
                  userActions.userListPageLimit({
                    page: usersData.page + 1,
                  })
                );
              }}
            >
              <RightOutlined />
            </Button>
          </Col>
          <Col>
            <Dropdown overlay={Mymenu}>
              <Button size="middle">
                {usersData.limit} <DownOutlined />
              </Button>
            </Dropdown>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CustomPagination;

import { authReducer } from "./slices/authSlice";
import storage from "redux-persist/lib/storage";
import { userReducer } from "./slices/userSlice";
import { subcriptionReducer } from "./slices/subcriptionSlice";
import { paymentReducer } from "./slices/paymentSlice";
import { faqsReducer } from "./slices/faqsSlice";
import { inquiryReducer } from "./slices/inquirySlice";
const { combineReducers } = require("@reduxjs/toolkit");
const { persistReducer } = require("redux-persist");

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  blacklist: [],
};

const authPersistConfig = {
  key: "auth",
  storage,
  keyPrefix: "redux-",
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  users: userReducer,
  subcription: subcriptionReducer,
  payments: paymentReducer,
  faqs: faqsReducer,
  inquiry: inquiryReducer,
});

export { rootReducer, rootPersistConfig };

import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import { selectIsAuthenticated } from "../redux/slices/authSlice";
import { PATH_DASHBOARD } from "../routes/path";

const GuestGuard = ({children}) => {
  const path = window.sessionStorage.getItem("pathname");
  const isAuthenticated = useSelector(selectIsAuthenticated);

  if (isAuthenticated) {
    if (path) {
      return <Navigate to={path} />;
    }
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  return <> {children} </>;
};

export default GuestGuard;

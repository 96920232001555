import React, { useState } from "react";
import { Card, Tabs } from "antd";
import PriceManage from "./PriceManage";
import Discount from "./Discount";

const { TabPane } = Tabs;

const PricingManagement = () => {
  const [currentTab, setCurrentTab] = useState("priceManage");

  const onChange = (key) => {
    setCurrentTab(key);
  };

  return (
    <>
      <h2>Price Management</h2>

      <Tabs activeKey={currentTab} onChange={onChange} type="card">
        <TabPane tab="Price Manage" key="priceManage">
          <Card>
            <PriceManage />
          </Card>
        </TabPane>
        <TabPane tab="Discount" key="discount">
          <Card>
            <Discount />
          </Card>
        </TabPane>
      </Tabs>
    </>
  );
};

export default PricingManagement;

import React, { useState } from "react";

import ListFaqs from "./ListFaqs";
import { Button } from "antd";
import AddEditFaqs from "../../sections/faqs/AddEditFaqs";

export const initialStateOfFaqs = {
  open: false,
  data: null,
  type: null,
  isLoading: false,
};

const Faqs = () => {
  const [state, setState] = useState(initialStateOfFaqs);

  return (
    <>
      <h2>FAQs Manage</h2>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        <Button
          onClick={() =>
            setState((pre) => ({ ...pre, open: true, type: "add" }))
          }
          type="primary"
        >
          Add Faqs
        </Button>
      </div>

      <div>
        <ListFaqs setState={setState} state={state} />
      </div>

      <AddEditFaqs open={state.open} setState={setState} state={state} />
    </>
  );
};

export default Faqs;

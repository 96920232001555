import { createSlice, createSelector } from "@reduxjs/toolkit";
import { put, select, takeLatest } from "redux-saga/effects";
import { faqsListApi } from "../apis/dashboardApi";

const initialState = {
  isLoading: true,
  error: null,
  success: false,
  list: [],
  viewData: null,
  sort: "createdAt",
  sortType: "desc",
  searchText: "",
  isNext: false,
};

const faqsSlice = createSlice({
  name: "faqs",
  initialState,
  reducers: {
    faqsList(state) {
      state.isLoading = true;
      state.searchText = "";
      state.isNext = false;
    },
    faqsListSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.list = action.payload.data;
    },
    faqsListFailed(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
      state.isNext = false;
      state.list = [];
    },
    faqsSearch(state, action) {
      state.searchText = action.payload.searchText;
    },
  },
});

export const {
  actions: faqsActions,
  reducer: faqsReducer,
  name: faqsKey,
} = faqsSlice;

const selectDomain = (state) => state.faqs || initialState;

export const selectFaqs = createSelector(
  [selectDomain],
  (faqsState) => faqsState
);

export const getState = (state) => state.faqs;

export function* faqsListReq() {
  try {
    const states = yield select(getState);
    const res = yield faqsListApi(states);

    if (res.data.success) {
      yield put(faqsActions.faqsListSuccess(res.data));
    } else {
      yield put(faqsActions.faqsListFailed(res.data));
    }
  } catch (error) {
    yield put(faqsActions.faqsListFailed(error));
  }
}

export function* faqsSaga() {
  yield takeLatest(faqsActions.faqsList.type, faqsListReq);
  yield takeLatest(faqsActions.faqsSearch.type, faqsListReq);
}

import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_API_URL;

const queryBuilder = (att) => {
  // console.log('att', att);
  let queryString = `?sort=${att.sort}&sortType=${att.sortType}`;

  if (att.limit) {
    queryString += "&limit=" + att.limit;
  }

  if (att.skip) {
    queryString += "&skip=" + (att.page - 1) * att.limit;
  }

  if (att.searchText) {
    queryString += "&search=" + att.searchText;
  }

  return queryString;
};

// user
export const userListApi = (data) => {
  let str = data ? queryBuilder(data) : "";

  return axios.get(`${baseUrl}/user/list` + str, { withCredentials: true });
};

export const getUserApi = (id) => {
  return axios.get(`${baseUrl}/user/get/${id}`, { withCredentials: true });
};

export const editUserApi = (id, data) => {
  return axios.put(`${baseUrl}/user/edit/${id}`, data, {
    withCredentials: true,
  });
};

export const updateProfile = (id, data) => {
  return axios.put(`${baseUrl}/user/edit/${id}`, data, {
    withCredentials: true,
  });
};

export const changePassword = (id, data) => {
  return axios.put(`${baseUrl}/user/change-password/${id}`, data, {
    withCredentials: true,
  });
};

// cms
export const editCmsApi = (data) => {
  return axios.put(`${baseUrl}/cms`, data, { withCredentials: true });
};

export const getCmsApi = (data) => {
  return axios.get(`${baseUrl}/cms?type=` + data.type, {
    withCredentials: true,
  });
};

// presets
export const editPreset = (data) => {
  return axios.put(`${baseUrl}/preset/update`, data, {
    withCredentials: true,
  });
};

export const apiGetPresetList = () => {
  return axios.get(`${baseUrl}/preset/get-list`, {
    withCredentials: true,
  });
};

export const apiPutPresetList = (data) => {
  return axios.put(`${baseUrl}/preset/put-list`, data, {
    withCredentials: true,
  });
};

export const apiDeletePresetInList = (data) => {
  return axios.put(`${baseUrl}/preset/delete-preset-in-list`, data, {
    withCredentials: true,
  });
};

// subscriptions
export const getUserpriceApi = (data) => {
  let str = "";

  if (data.userId) {
    str += "?userId=" + data.userId;
  }

  return axios.get(`${baseUrl}/subcription/get-user-price` + str, {
    withCredentials: true,
  });
};

export const putUserpriceApi = (data) => {
  return axios.put(`${baseUrl}/subcription/set-user-price`, data, {
    withCredentials: true,
  });
};

export const putCouponCodeApi = (data) => {
  return axios.put(`${baseUrl}/subcription/set-coupon-code`, data, {
    withCredentials: true,
  });
};

export const deleteCouponCode = (data) => {
  return axios.put(`${baseUrl}/subcription/delete-coupon-code`, data, {
    withCredentials: true,
  });
};

export const getCouponCodeApi = () => {
  return axios.get(`${baseUrl}/subcription/get-coupon-code`, {
    withCredentials: true,
  });
};

export const getTransactionListApi = (data) => {
  let str = data ? queryBuilder(data) : "";

  return axios.get(`${baseUrl}/payment/list-payment-transactions` + str, {
    withCredentials: true,
  });
};

export const editTransactionApi = (id, data) => {
  return axios.put(
    `${baseUrl}/payment/edit-transaction-for-admin/${id}`,
    data,
    {
      withCredentials: true,
    }
  );
};

// faqs
export const faqsListApi = (data) => {
  let str = data ? queryBuilder(data) : "";

  return axios.get(`${baseUrl}/faqs/list` + str, { withCredentials: true });
};

export const getFaqsApi = (id) => {
  return axios.get(`${baseUrl}/faqs/get/${id}`, { withCredentials: true });
};

export const editFaqsApi = (id, data) => {
  return axios.put(`${baseUrl}/faqs/edit/${id}`, data, {
    withCredentials: true,
  });
};

export const addFaqsApi = (data) => {
  return axios.post(`${baseUrl}/faqs/add`, data, {
    withCredentials: true,
  });
};

export const deleteFaqsApi = (id) => {
  return axios.delete(`${baseUrl}/faqs/delete/${id}`, {
    withCredentials: true,
  });
};

// inquiry
export const listInquiriesApi = (data) => {
  let str = data ? queryBuilder(data) : "";

  return axios.get(`${baseUrl}/inquiry/list` + str);
};

import { createSlice, createSelector } from "@reduxjs/toolkit";
import { put, select, takeLatest } from "redux-saga/effects";
import { getTransactionListApi } from "../apis/dashboardApi";

const initialState = {
  isLoading: true,
  error: null,
  success: false,
  list: [],
  viewData: null,
  limit: 10,
  page: 1,
  sort: "createdAt",
  sortType: "desc",
  searchText: "",
  isNext: false,
};

const paymentSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    transactionList(state, action) {
      state.isLoading = true;
      state.limit = action.payload.limit;
      state.page = action.payload.page;
      state.searchText = "";
      state.isNext = false;
    },
    transactionListSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.list = action.payload.data;
      state.isNext = action.payload.isNext;
    },
    transactionListFailed(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
      state.isNext = false;
      state.list = [];
    },
    transactionListPageLimit(state, action) {
      state.page = action.payload.page;
      state.limit = 10;
      state.isLoading = true;
    },
    transactionListLimit(state, action) {
      state.page = 1;
      state.limit = action.payload.limit;
      state.isLoading = true;
    },
    transactionSearch(state, action) {
      state.searchText = action.payload.searchText;
      state.page = 1;
      state.limit = 10;
    },
  },
});

export const {
  actions: paymentActions,
  reducer: paymentReducer,
  name: paymentKey,
} = paymentSlice;

const selectDomain = (state) => state.payments || initialState;

export const selectPayment = createSelector(
  [selectDomain],
  (paymentState) => paymentState
);

export const getState = (state) => state.payments;

export function* transactionListReq() {
  try {
    const states = yield select(getState);
    const res = yield getTransactionListApi(states);

    if (res.data.success) {
      yield put(paymentActions.transactionListSuccess(res.data));
    } else {
      yield put(paymentActions.transactionListFailed(res.data));
    }
  } catch (error) {
    yield put(paymentActions.transactionListFailed(error));
  }
}

export function* payemntSaga() {
  yield takeLatest(paymentActions.transactionList.type, transactionListReq);
  yield takeLatest(
    paymentActions.transactionListPageLimit.type,
    transactionListReq
  );
  yield takeLatest(
    paymentActions.transactionListLimit.type,
    transactionListReq
  );
  yield takeLatest(paymentActions.transactionSearch.type, transactionListReq);
}

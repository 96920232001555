import React, { useEffect, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Table, message } from "antd";
import { selectLoggedUser } from "../../redux/slices/authSlice";
import {
  deleteCouponCode,
  putCouponCodeApi,
} from "../../redux/apis/dashboardApi";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSubcription,
  subcriptionActions,
} from "../../redux/slices/subcriptionSlice";

const initialState = {
  price: null,
  name: "",
  isLoading: false,
};

const Discount = () => {
  const dispatch = useDispatch();

  const loggedUser = useSelector(selectLoggedUser);
  const subcriptionData = useSelector(selectSubcription);

  const [state, setState] = useState(initialState);

  useEffect(() => {
    dispatch(subcriptionActions.couponCodeList());
    // eslint-disable-next-line
  }, []);

  const handleSave = async () => {
    try {
      if (state?.name && state?.price) {
        const payload = {
          userId: loggedUser?._id,
          couponCode: {
            name: state?.name,
            price: Number(state?.price),
          },
        };

        setState({
          ...state,
          isLoading: true,
        });

        const res = await putCouponCodeApi(payload);

        if (res?.data?.success) {
          setState({
            ...state,
            isLoading: false,
            name: null,
            price: null,
          });

          dispatch(subcriptionActions.couponCodeList());
          message.success(res?.data?.message);
        } else {
          setState({
            ...state,
            isLoading: false,
          });
          message.error(res?.data?.message);
        }
      } else {
        message.error("Please enter coupon name & price");
      }
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
      });
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const handleDelete = async (payload) => {
    try {
      const data = {
        userId: loggedUser?._id,
        couponCode: {
          name: payload?.name,
          price: Number(payload?.price),
          _id: payload?._id,
        },
      };

      setState({
        ...state,
        isLoading: true,
      });

      const res = await deleteCouponCode(data);

      if (res?.data?.success) {
        setState({
          ...state,
          isLoading: false,
        });
        dispatch(subcriptionActions.couponCodeList());
        message.success(res?.data?.message);
      } else {
        setState({
          ...state,
          isLoading: false,
        });
        message.error(res?.data?.message);
      }
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
      });
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (val, elm) => {
        return <div>{elm?.name}</div>;
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (val, elm) => {
        return <div>{elm?.price}</div>;
      },
    },
    {
      title: "Actions",
      render: (_, elm) => {
        return (
          <>
            <div>
              <Button onClick={() => handleDelete(elm)} danger type="text">
                <DeleteOutlined style={{ fontSize: "18px" }} />
              </Button>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <div>
          <p>Coupon Code</p>
          <div style={{ display: "flex", gap: "20px" }}>
            <Input
              value={state.name}
              onChange={(event) =>
                setState({
                  ...state,
                  name: event?.target?.value?.toUpperCase(),
                })
              }
              placeholder="Coupon code"
            />
            <Input
              value={state.price}
              onChange={(event) =>
                setState({ ...state, price: Number(event?.target?.value) })
              }
              type="number"
              placeholder="Coupon price"
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              loading={state.isLoading}
              onClick={handleSave}
              type="primary"
            >
              Save
            </Button>
          </div>
        </div>

        <Divider />

        <div>
          <Table
            loading={subcriptionData?.isLoading}
            dataSource={subcriptionData?.couponList}
            columns={columns}
          />
        </div>
      </div>
    </>
  );
};

export default Discount;

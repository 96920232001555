import { createSlice, createSelector } from "@reduxjs/toolkit";
import { put, select, takeLatest } from "redux-saga/effects";
import { getCouponCodeApi, getUserpriceApi } from "../apis/dashboardApi";

const initialState = {
  isLoading: true,
  error: null,
  success: false,
  viewData: null,
  userId: null,
  couponList: [],
};

const subcriptionSlice = createSlice({
  name: "subcription",
  initialState,
  reducers: {
    subcription(state, action) {
      state.isLoading = true;
      state.userId = action.payload.userId;
    },
    subcriptionSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload.success;
      state.viewData = action.payload.data;
    },
    subcriptionFailed(state, action) {
      state.isLoading = false;
      state.success = action.payload.success;
      state.viewData = null;
      state.success = true;
    },
    couponCodeList(state) {
      state.isLoading = true;
      state.success = false;
    },
    couponCodeListSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload.success;
      state.couponList = action.payload.data?.couponCode || [];
    },
    couponCodeListFailed(state, action) {
      state.isLoading = false;
      state.success = action.payload.success;
      state.viewData = null;
      state.success = true;
    },
  },
});

export const {
  actions: subcriptionActions,
  reducer: subcriptionReducer,
  name: subcriptionName,
} = subcriptionSlice;

const selectDomain = (state) => state.subcription || initialState;

export const selectSubcription = createSelector(
  [selectDomain],
  (satte) => satte
);

export const getState = (state) => state.subcription;

export function* subcriptionReq() {
  try {
    const states = yield select(getState);
    const res = yield getUserpriceApi(states);

    if (res.data.success) {
      yield put(subcriptionActions.subcriptionSuccess(res.data));
    } else {
      yield put(subcriptionActions.subcriptionFailed(res.data));
    }
  } catch (error) {
    yield put(subcriptionActions.subcriptionFailed(error));
  }
}

export function* couponCodeReq() {
  try {
    const res = yield getCouponCodeApi();

    if (res.data.success) {
      yield put(subcriptionActions.couponCodeListSuccess(res.data));
    } else {
      yield put(subcriptionActions.couponCodeListFailed(res.data));
    }
  } catch (error) {
    yield put(subcriptionActions.couponCodeListFailed(error));
  }
}

export function* subcriptionSaga() {
  yield takeLatest(subcriptionActions.subcription.type, subcriptionReq);
  yield takeLatest(subcriptionActions.couponCodeList.type, couponCodeReq);
}

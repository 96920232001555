import { Button, Card, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { editCmsApi, getCmsApi } from "../../redux/apis/dashboardApi";

const PrivacyPolicy = () => {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);

  const getCms = async () => {
    try {
      const data = {
        type: "privacy",
      };
      setLoading(true);
      const res = await getCmsApi(data);
      if (res?.data?.success) {
        setLoading(false);
        setValue(res?.data?.data);
      } else {
        setLoading(false);
        setValue("");
        message.error(res?.data?.message);
      }
    } catch (error) {
      setLoading(false);
      setValue("");
      message.error(error?.respose?.data?.message);
    }
  };

  useEffect(() => {
    getCms();
  }, []);

  const handleSave = async () => {
    try {
      if (value === "" || value === "<p><br></p>" || value === "<p> </p>") {
        message.error("Please Write Something!");
      } else {
        const data = {
          content: value,
          type: "privacy",
        };
        const res = await editCmsApi(data);
        if (res?.data?.success) {
          getCms();
          message.success(res?.data?.message);
        } else {
          message.error(res?.data?.message);
        }
      }
    } catch (error) {
      message.error(error?.respose?.data?.message);
    }
  };

  return (
    <>
      <Card>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <>
            <ReactQuill
              placeholder="Write Privacy Policy Here..."
              theme="snow"
              value={value}
              onChange={setValue}
            />

            <div>
              <Button
                onClick={handleSave}
                type="primary"
                style={{ marginTop: "10px", width: "15%" }}
              >
                Save Changes
              </Button>
            </div>
          </>
        )}
      </Card>
    </>
  );
};

export default PrivacyPolicy;

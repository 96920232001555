import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router";
import { selectIsAuthenticated } from "../redux/slices/authSlice";
import { PATH_AUTH } from "../routes/path";

const AuthGuard = ({ children }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { pathname } = useLocation();

  window.sessionStorage.setItem("pathname", pathname);

  const [requestedLocation, setRequestedLocation] = useState(null);

  const path = window.sessionStorage.getItem("pathname");
  
  if (!isAuthenticated) {
    if (path !== requestedLocation) {
      setRequestedLocation(path);
    }
    return <Navigate to={PATH_AUTH.root} />;
  }

  if (requestedLocation && path !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <> {children} </>;
};

export default AuthGuard;

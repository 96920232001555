import React from "react";
import { Button, Row, Col, Dropdown, Menu } from "antd";
import { LeftOutlined, RightOutlined, DownOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { paymentActions, selectPayment } from "../../redux/slices/paymentSlice";

export function CustomPaymentPagination() {
  const pageSizeOptions = ["10", "20", "50"];
  const paymentData = useSelector(selectPayment);

  const dispatch = useDispatch();

  const Mymenu = (
    <Menu>
      {pageSizeOptions.map((e, i) => (
        <Menu.Item
          onClick={() => {
            dispatch(paymentActions.transactionListLimit({ limit: Number(e) }));
          }}
          key={i}
        >
          {e}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Row gutter={10}>
          <Col>
            <Button
              size="middle"
              disabled={paymentData.page <= 1}
              onClick={() => {
                dispatch(
                  paymentActions.transactionListPageLimit({
                    page: paymentData.page - 1,
                  })
                );
              }}
            >
              <LeftOutlined />
            </Button>
          </Col>
          <Col>
            <Button size="middle">{paymentData.page}</Button>
          </Col>
          <Col>
            <Button
              size="middle"
              disabled={!paymentData.isNext}
              onClick={() => {
                dispatch(
                  paymentActions.transactionListPageLimit({
                    page: paymentData.page + 1,
                  })
                );
              }}
            >
              <RightOutlined />
            </Button>
          </Col>
          <Col>
            <Dropdown overlay={Mymenu}>
              <Button size="middle">
                {paymentData.limit} <DownOutlined />
              </Button>
            </Dropdown>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CustomPaymentPagination;

import { createSlice, createSelector } from "@reduxjs/toolkit";
import { put, select, takeLatest } from "redux-saga/effects";
import { userListApi } from "../apis/dashboardApi";

const initialState = {
  isLoading: true,
  error: null,
  success: false,
  list: [],
  viewData: null,
  limit: 10,
  page: 1,
  sort: "createdAt",
  sortType: "desc",
  searchText: "",
  isNext: false,
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    userList(state, action) {
      state.isLoading = true;
      state.limit = action.payload.limit;
      state.page = action.payload.page;
      state.searchText = "";
      state.isNext = false;
    },
    userListSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.list = action.payload.data;
      state.isNext = action.payload.isNext;
    },
    userListFailed(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
      state.isNext = false;
      state.list = [];
    },
    userListPageLimit(state, action) {
      state.page = action.payload.page;
      state.limit = 10;
      state.isLoading = true;
    },
    userListLimit(state, action) {
      state.page = 1;
      state.limit = action.payload.limit;
      state.isLoading = true;
    },
    userSearch(state, action) {
      state.searchText = action.payload.searchText;
      state.page = 1;
      state.limit = 10;
    }
  },
});

export const {
  actions: userActions,
  reducer: userReducer,
  name: userKey,
} = userSlice;

const selectDomain = (state) => state.users || initialState;

export const selectUser = createSelector(
  [selectDomain],
  (userState) => userState
);

export const getState = (state) => state.users;

export function* userListReq() {
  try {
    const states = yield select(getState);
    const res = yield userListApi(states);

    if (res.data.success) {
      yield put(userActions.userListSuccess(res.data));
    } else {
      yield put(userActions.userListFailed(res.data));
    }
  } catch (error) {
    yield put(userActions.userListFailed(error));
  }
}

export function* userSaga() {
  yield takeLatest(userActions.userList.type, userListReq);
  yield takeLatest(userActions.userListPageLimit.type, userListReq);
  yield takeLatest(userActions.userListLimit.type, userListReq);
  yield takeLatest(userActions.userSearch.type, userListReq);
}

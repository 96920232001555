import { Button, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoggedUser } from "../../redux/slices/authSlice";
import { putUserpriceApi } from "../../redux/apis/dashboardApi";
import {
  selectSubcription,
  subcriptionActions,
} from "../../redux/slices/subcriptionSlice";

const initialState = {
  price: null,
  isLoading: false,
};

const PriceManage = () => {
  const dispatch = useDispatch();

  const loggedUser = useSelector(selectLoggedUser);
  const subcriptionData = useSelector(selectSubcription);

  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (loggedUser)
      dispatch(subcriptionActions.subcription({ userId: loggedUser?._id }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (subcriptionData) {
      setState({
        ...state,
        price: subcriptionData?.viewData?.perUserPrice || 0,
      });
    }
    // eslint-disable-next-line
  }, [subcriptionData]);

  const handleSavePrice = async () => {
    try {
      if (state.price !== null) {
        setState({
          ...state,
          isLoading: true,
        });

        const payload = {
          userId: loggedUser?._id,
          perUserPrice: Number(state?.price),
        };

        const res = await putUserpriceApi(payload);

        if (res?.data?.success) {
          setState({
            ...state,
            isLoading: false,
          });
          message.success(res?.data?.message);
          dispatch(subcriptionActions.subcription({ userId: loggedUser?._id }));
        } else {
          setState({
            ...state,
            isLoading: false,
          });
          message.error(res?.data?.message);
        }
      } else {
        setState({
          ...state,
          isLoading: false,
        });
        message.error("Please enter a price");
      }
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
      });
      message.error(
        error?.response?.data?.message || "Something went wrong happened"
      );
    }
  };

  return (
    <>
      <div style={{ padding: "10px 20px" }}>
        <h3 style={{ fontWeight: 500 }}>User Subcription Price</h3>

        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <div style={{ width: "50%" }}>
            <span>Price (per user) (including GST)</span>
            <Input
              prefix={"Rs."}
              value={state.price}
              onChange={(e) => {
                setState({
                  ...state,
                  price: e.target.value,
                });
              }}
              style={{ marginTop: "10px", fontWeight: 500 }}
              placeholder="Set price"
              type="number"
            />
          </div>
          <div>
            <Button
              loading={state.isLoading}
              onClick={handleSavePrice}
              type="primary"
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceManage;

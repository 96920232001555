import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export const registerApi = (data) => {
  return axios.post(`${baseUrl}/auth/register`, data, {
    withCredentials: true,
  });
};

export const loginApi = (data) => {
  return axios.post(`${baseUrl}/auth/login`, data,  { withCredentials: true });
};

export const isLoggedInApi = () => {
  return axios.get(`${baseUrl}/auth/isLoggedIn`, { withCredentials: true });
};

export const logOutApi = () => {
  return axios.get(`${baseUrl}/auth/logout`, { withCredentials: true });
};

export const forgotPasswordApi = (data) => {
  return axios.post(`${baseUrl}/auth/forgot-password`, data);
};

import { Col, Form, Input, Modal, Row, message } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { initialStateOfFaqs } from "../../pages/Faqs";
import { addFaqsApi, editFaqsApi } from "../../redux/apis/dashboardApi";
import { faqsActions } from "../../redux/slices/faqsSlice";

const rules = {
  question: [{ required: true, message: "Please Enter Question" }],
  answer: [{ required: true, message: "Please Enter Answer" }],
};

const AddEditFaqs = ({ open, setState, state }) => {
  const dispatch = useDispatch();

  const [addEditFaqsForm] = Form.useForm();

  const handleCancel = () => {
    setState(initialStateOfFaqs);
    addEditFaqsForm.resetFields();
  };

  useEffect(() => {
    if (state?.type === "edit") {
      addEditFaqsForm.setFieldsValue({
        question: state?.data?.title || "",
        answer: state?.data?.description || "",
      });
    }
    // eslint-disable-next-line
  }, [state]);

  const onSubmit = async (data) => {
    try {
      let payload = {
        title: data?.question || "-",
        description: data?.answer || "-",
      };

      setState((pre) => ({ ...pre, isLoading: true }));

      let res = null;

      if (state?.type === "edit") {
        res = await editFaqsApi(state?.data?._id, payload);
      } else {
        res = await addFaqsApi(payload);
      }

      if (res?.data?.message) {
        message.success(res?.data?.message);

        addEditFaqsForm.resetFields();
        setState(initialStateOfFaqs);

        dispatch(faqsActions.faqsList());
      } else {
        setState(initialStateOfFaqs);
        message.error(res?.data?.message);
      }
    } catch (error) {
      setState(initialStateOfFaqs);
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  return (
    <>
      <Modal
        title={`${state?.type === "add" ? "Add" : "Edit"} Faqs`}
        visible={open}
        onCancel={handleCancel}
        okText="Submit"
        onOk={() => addEditFaqsForm.submit()}
        okButtonProps={{
          htmlType: "submit",
          type: "primary",
          loading: state?.isLoading,
        }}
      >
        <div>
          <Form
            onFinish={onSubmit}
            form={addEditFaqsForm}
            name="addEditFaqsForm"
            layout="vertical"
          >
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item
                  label="Question"
                  name="question"
                  rules={rules.question}
                >
                  <Input placeholder="Question" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={12}>
              <Col span={24}>
                <Form.Item label="Answer" name="answer" rules={rules.answer}>
                  <ReactQuill
                    value={addEditFaqsForm.getFieldValue("answer")}
                    theme="snow"
                    placeholder="Answer"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default AddEditFaqs;

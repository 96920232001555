export const PATH_DASHBOARD = {
  root: "/app/user/list",
  userList: "/app/user/list",
  editUser: (id) => `/app/user/edit/${id}`,
  cms: "/app/cms",
  faqs: "/app/faqs",
  preset: "/app/preset",
  profile: "/app/profile",
  pricingManagement: "/app/price-management",
  paymentManagement: "/app/payment-management",
  inquiries: "/app/inquiries",
};

export const PATH_AUTH = {
  login: "/auth/login",
  root: "/auth/login",
  register: "/auth/register",
};
